import { MDBCol, MDBContainer, MDBRow } from 'mdbreact';

import BackLink from '../../components/backlink';
import BreadcrumbMenu from '../../components/menus/corphappeningsbcmenu';
import InlineBodyTransform from '../../components/inlinebodytransform';
import Layout from '../../components/layout';
import Navbar from '../../components/menus/happeningsnavbarmenu';
import React from 'react';
import SEO from '../../components/seo';
import { graphql } from 'gatsby';

let gmdv = require(`../../ghc_config`);

const Detail = ({ data }) => {

  const post = data.nodeCorporateHappenings

  let dateString = gmdv.dateProcessed(post.field_happening_s_date)

  dateString = dateString.toLocaleString('en-us', { 
    month: 'numeric', 
    day: 'numeric', 
    year: 'numeric',
  });

  return (
    <Layout>
      <SEO 
        title={post.title}
        description={`Genesis HealthCare offers long term care services, short stay care, transitional care services, rehab services and specialty services`}
        keywords={[`Long Term Care Services`, `Transitional Care`, `Transitional Care Services`, `Genesis Rehab`]} 
      />
      <main>
        <MDBContainer tag="section" id="maincontent">
          <MDBRow center>
            <MDBCol xs="12" md="3" lg="3" className="col-12 leftside">
              <Navbar />
            </MDBCol>
            <MDBCol xs="12" md="9" lg="9" className="col-12 contentRight editCont">
            <h1 className="pagetitle" 
                style={{backgroundImage: `url(${'/images/banners/SectionsBox/bnr-happenings2-trimmed.png'})`}}>
                Happenings
              </h1>
              <BreadcrumbMenu title={post.title}/>
              <BackLink/>
              <h5>{post.title}</h5>
              <small>{dateString}</small>
              <InlineBodyTransform bodyValue={post.body} />
              <BackLink bottom/>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </main>
    </Layout>
  )
}

export default Detail;

export const query = graphql`
  query($id: String!) {
    nodeCorporateHappenings(id: { eq: $id }) {
      field_happening_s_date
      title
      body {
        value
      }
      path {
        alias
      }
    }
  }
`